<template>
  <div>
    <div>
      <span>选择社区:</span>
      <Select
        v-model="region"
        style="width: 150px;margin: 0 10px 10px 24px"
        placeholder="请选择区"
        @on-change="changeRegion"
        filterable
      >
        <Option
          :value="item.orgCode"
          v-for="(item, index) in regionList"
          :key="index"
        >{{ item.orgName }}</Option>
      </Select>
      <Select
        v-model="stree"
        style="width: 150px;margin: 0 10px 10px 0"
        placeholder="请选择街道/乡镇"
        @on-change="changeStree"
        filterable
      >
        <Option
          :value="item.orgCode"
          v-for="(item, index) in streeList"
          :key="index"
        >{{ item.orgName }}</Option> 
      </Select>
      <Select
        v-model="project"
        style="width: 150px;margin: 0 10px 10px 0"
        placeholder="请选择社区"
        clearable
        filterable
        @on-change="changeProject"
      >
        <Option
          :value="item.orgCode"
          v-for="(item, index) in projectList"
          :key="index"
        >{{ item.orgName }}</Option>
      </Select>
      <Button type="primary" style="margin-bottom:10px;" @click="makeQRCode">生成小程序码</Button>
    </div>
    <h3>该链接可以用于配置到公众号进行推广</h3>
    <div
      style="
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        margin: 14px auto;
      "
    >
      <span>公众号链接：</span>
      <Input
        v-model="officialUrl"
        search
        enter-button="复制"
        style="flex: 1"
        @on-search="copyShareCode"
        :disabled="isOk"
      ></Input>
    </div>
    <!-- <div
      style="
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        margin: 14px auto;
      "
    >
      <span style="width:84px">短信链接：</span>
      <Input
        v-model="noteUrl"
        search
        enter-button="复制"
        style="flex: 1;margin-right: 100px"
        @on-search="copyNoteUrl"
      ></Input>
    </div> -->
    <h3>推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）</h3>
    <div style="display: flex; align-items: center; justify-content: center">
      <div class="qrcode" ref="haibaoImg">
        <div class="h1h3">
          <h1>网上家园沟通组邀请码</h1>
          <h3>入驻{{qrOCdeName | spliceTitle}}</h3>
        </div>
        <!-- qrOCdeName -->
        <p>微信扫码完善信息进入沟通组</p>
        <span>{{ qrCodeOrgName }}</span>
        <img
          :src="codeImgUrl + '?' + new Date().getTime()"
          alt
          ref="qrCode"
          crossorigin="anonymous"
        />
      </div>
      <div style="width: 130px; height: 100%; margin-left: 30px">
        <Button
          type="primary"
          @click="downloadImgCode"
          style="margin-bottom: 20px; width: 130px"
          :disabled="isOk"
        >下载纯二维码</Button>
        <Button type="warning" @click="mergeImage" :disabled="isOk">下载宣传二维码</Button>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  props: {
    isOpen: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    cityOper:{
      type:Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {
      officialUrl: "",
      qrOCdeName: "",
      qrCodeOrgName: parent.vue.loginInfo.userinfo.orgName,
      codeImgUrl: "",
      region: "",
      stree: "",
      project: "",
      projectName: "",
      regionList: [],
      streeList: [],
      projectList: [],
      isOk: true,
      mappingCode: "",
      // 短信链接
      noteUrl:'',
      noteCode:'',
    };
  },
  methods: {
    changeProject() {
      console.log('1秒的',this.project);
        this.projectList.map(item => {
          if (item.orgCode == this.project) {
            this.projectName = item.orgName;
            this.mappingCode = item.mappingCode;
          }
        });
        console.log('this.projectName',this.projectName);
        console.log('this.mappingCode',this.mappingCode);
    },
    // 点击copy按钮
    copyShareCode() {
      // 如果还没点击生成小程序码，就跑这里
      if (this.isOk == true) {
        this.$Message.warning({
          content: "请先生成小程序码",
          background: true
        });
        return;
      } else {
        var oInput = document.createElement("input");
        oInput.value = this.officialUrl;
        document.body.appendChild(oInput);
        oInput.select();
        document.execCommand("Copy");
        oInput.className = "oInput";
        oInput.style.display = "none";
        document.body.removeChild(oInput);
        this.$Message.success({
          background: true,
          content: "复制链接成功！"
        });
      }
    },
    // 点击复制短信链接
    copyNoteUrl() {
      var oInput = document.createElement("input");
      oInput.value = this.noteUrl;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$Message.success({
        background: true,
        content: "复制链接成功！"
      });
    },
    // 点击下载二维码按钮
    downloadImgCode() {
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "社区推广二维码";
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = this.codeImgUrl;
    },
    // 点击下载宣传二维码
    mergeImage() {
      html2canvas(this.$refs.haibaoImg, { useCORS: true }).then(function(
        canvas
      ) {
        let src = canvas.toDataURL();
        var image = new Image();

        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function() {
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png", 1.0);
          var a = document.createElement("a");
          var event = new MouseEvent("click");
          a.download = "社区宣传二维码";
          a.href = url;
          a.dispatchEvent(event);
        };
        image.src = src;
      });
    },
    // 区
    selectRegion(code) {
      this.region = "";
      this.stree = "";
      this.project = "";
      this.regionList = [];
      this.streeList = [];
      this.projectList = [];
      if (code == null || code == "") return;
      this.getPulishData(code, "regionList");
    },
    // 校验
    makeQRCode() {
      if (!this.project || this.project == "") {
        this.$Message.error({
          background: true,
          content: "只能生成社区的小程序码，请选择社区"
        });
        return;
      }
      this.$Message.loading({
        content: "正在生成小程序码...",
        duration: 0
      });
      this.openOfficial();
    },

    // 区选择
    changeRegion(code) {
      this.stree = "";
      this.project = "";
      this.streeList = [];
      this.projectList = [];
      this.getPulishData(code, "streeList");
    },
    // 街道选择
   async changeStree(code) {
      this.project = "";
      this.projectList = [];
       await  this.getPulishData(code, "projectList");
      
    },
    // 接口部分
    openOfficial() {
      let type = "";
      let params = {};
      type = "GRID_GROUP";
      params = {
        communityList: [
          {
            projectCode: this.mappingCode,
            projectName: this.projectName
          }
        ],
        orgCode: this.project,
        orgName: this.projectName
      };
      this.$post(
        "/datamsg/api/pc/wxqr/createWxAppletQr",
        {
          generateType: "1",
          width: 200,
          type: type,
          value: JSON.stringify({
            path: "pages/welcome/welcome",
            redirectPath: "modules/2021/pages/gridgroup/index",
            params: params
          }),
          appletCode: "suiyue"
        },
        { "Content-Type": "application/json" }
      )
        .then(res => {
          this.$Message.destroy();
          if (res.code == 200) {
            this.isOk = false;
            this.qrOCdeName = this.projectName;
            this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code;
            this.codeImgUrl = res.data.codeUrl;
            this.noteCode = res.data.code
            // this.$post(
            //   "/verifyapi/api/sytop/pc/urlLink/createUrlLink",
            //   {
            //     path:'pages/welcome/welcome',
            //     query: `scene=${this.noteCode}`,
            //   },
            // ).then(res => {
            //   if (res.code == 200 && res.data) {
            //     this.noteUrl = res.data.link
            //   }
            // });
          } else {
            this.$Message.error({
              background: true,
              content: "公众号链接生成失败！请联系管理员解决"
            });
          }
        })
        .catch(err => {
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "获取群二维码失败，请联系管理员处理"
          });
        });
    },
    // 获取范围列表
    async getPulishData(code, list) {
      await  this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      }).then( res => {
        if (res.code == 200) {
          this[list] = res.dataList;
        }
      });
    }
  },
  created() {
    // this.openOfficial();
  },
  filters:{
    spliceTitle(option){
      if(option.length >22){
        return option = option.slice(0, 22) + '...'
      }else{
        return option
      }
    }
  },
  watch: {
    isOpen: {
      handler(val, newVal) {
        if (val) {
          this.officialUrl = "";
          this.qrOCdeName = "";
          this.qrCodeOrgName = parent.vue.loginInfo.userinfo.orgName;
          this.codeImgUrl = "";
          this.region = "";
          this.stree = "";
          this.project = "";
          this.projectName = "";
          this.regionList = [];
          this.streeList = [];
          this.projectList = [];
          this.isOk = true;
          this.selectRegion("4401");
        }
      }
    },
    cityOper:{
     async handler(val,newVal){
        if(val){
          console.log('val',val);
          this.changeRegion(val.region)
        await  this.changeStree(val.stree)
        
          // setTimeout(()=>{
            this.region = val.region
            this.stree = val.stree
            this.project = val.project
            this.qrCodeOrgName = val.qrCodeOrgName

            console.log('2秒的',this.project);
              this.changeProject()
            this.makeQRCode()
            
          // },200)
          // setTimeout(()=>{
            
          // },100)
           
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.qrcode {
  position: relative;
  width: 400px;
  height: 565px;
  background: url("/images/pic_hd.jpg") no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    top: 256px;
    left: 83px;
    width: 230px;
  }
  .h1h3 {
    width: 100%;
    position: absolute;
    top: 75px;
    text-align: center;
  }
  h1 {
    text-align: center;
    font-size: 32px;
    color: #c62e23;
    margin-bottom: 10px;
  }
  h3 {
    padding: 0 30px;
    text-align: center;
    font-size: 26px;
    color: #c62e23;
  }
  p {
    width: 100%;
    position: absolute;
    top: 223px;
    text-align: center;
    font-size: 16px;
    color: #000;
  }
  span {
    width: 100%;
    position: absolute;
    text-align: center;
    top: 488px;
    font-size: 17px;
    color: #000;
  }
}
</style>